$(function() {

    function getTimeRemaining(endtime) {
        var t = Date.parse(endtime) - Date.parse(new Date());
        var seconds = Math.floor((t / 1000) % 60);
        var minutes = Math.floor((t / 1000 / 60) % 60);
        var hours = Math.floor((t / (1000 * 60 * 60)) % 24);
        var days = Math.floor(t / (1000 * 60 * 60 * 24));
        return {
            'total': t,
            'days': days,
            'hours': hours,
            'minutes': minutes,
            'seconds': seconds
        };
    }

    function initializeClock(id, endtime) {
        var clock = id;
        var daysSpan = clock.find('.days');
        var hoursSpan = clock.find('.hours');
        var minutesSpan = clock.find('.minutes');
        var secondsSpan = clock.find('.seconds');

        function updateClock() {
            console.log(getTimeRemaining(endtime));
            var t = getTimeRemaining(endtime);

            daysSpan.html(t.days);
            hoursSpan.html(('0' + t.hours).slice(-2));
            minutesSpan.html(('0' + t.minutes).slice(-2));
            secondsSpan.html(('0' + t.seconds).slice(-2));

            if (t.total <= 0) {
                clearInterval(timeinterval);
            }
        }

        updateClock();
        var timeinterval = setInterval(updateClock, 1000);
    }

    if($('div').hasClass('section2__timer')){
        console.log('1');
        var deadline="November 08 2018 20:00:00 GMT+0300";
        initializeClock($('.section2__timer'), deadline);
    }
});

