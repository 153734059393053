$(function() {


    $('.header__mobile-btn').on('click',function(e){
        e.preventDefault();


        $('.header').toggleClass('active');
    });
}); 

