$(function() {
    $('.section2__tabs-head a').on('click',function(e){
        e.preventDefault();

        $('.section2__tabs-head a').removeClass('active');
        $(this).addClass('active');
        $('.section2__tabs-body>div').removeClass('active');
        $('.section2__tabs-body #'+$(this).data('go')).addClass('active');
    });
});

